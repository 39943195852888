define("discourse/plugins/user-explorer/discourse/connectors/above-site-header/user-poll", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      const poll = () => {
        try {
          (0, _ajax.ajax)("/ue/poll", {
            type: "PUT",
            data: {}
          }).then(result => {});
        } catch (e) {}
      };
      window.addEventListener("load", () => {
        setInterval(poll, this.siteSettings.user_explorer_user_poll_rate);
      });
    }
  };
});