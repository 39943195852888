define("discourse/plugins/user-explorer/discourse/connectors/admin-user-details/user-data", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      let data = {};
      try {
        (0, _ajax.ajax)(`/user_explorer/${window.location.href.split('/').find(part => part.match(/^-?\d+$/))}`, {
          type: "GET",
          data: {}
        }).then((result => {
          const isEmpty = obj => obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
          data = result?.user_explorer_query?.[0] ?? {};
          const formatedData = {};
          Object.keys(data).forEach(function (key, index) {
            formatedData[key.replaceAll('_', ' ')] = data[key] === null ? 'n/a' : data[key];
          }.bind());
          // set for template
          component.set('userdata', isEmpty(formatedData) ? false : formatedData);
          // set for copying
          this.formatedData = formatedData;
        }).bind());
      } catch (e) {
        component.set('userdata', false);
      }
    },
    actions: {
      copy() {
        // source: https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
        const fallbackCopyTextToClipboard = text => {
          var textArea = document.createElement("textarea");
          textArea.value = text;

          // Avoid scrolling to bottom
          textArea.style.top = "0";
          textArea.style.left = "0";
          textArea.style.position = "fixed";
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log('Fallback: Copying text command was ' + msg);
          } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
          }
          document.body.removeChild(textArea);
        };
        function copyTextToClipboard(text) {
          if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(text);
            return;
          }
          navigator.clipboard.writeText(text).then(function () {
            console.log('Async: Copying to clipboard was successful!');
          }, function (err) {
            console.error('Async: Could not copy text: ', err);
          });
        }
        fallbackCopyTextToClipboard(Object.entries(this?.formatedData ?? {}).map(value => `${value[0]}: ${value[1]}`).join('\n'));
      }
    }
  };
});