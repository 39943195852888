define("discourse/plugins/user-explorer/discourse/templates/connectors/above-site-header/user-poll", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <section style="display:contents;width:0px;height:0px;"></section>
  */
  {
    "id": "pvuhiWGg",
    "block": "[[[10,\"section\"],[14,5,\"display:contents;width:0px;height:0px;\"],[12],[13]],[],false,[]]",
    "moduleName": "discourse/plugins/user-explorer/discourse/templates/connectors/above-site-header/user-poll.hbs",
    "isStrictMode": false
  });
});