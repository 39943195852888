define("discourse/plugins/user-explorer/discourse/user-explorer-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  /**
   * Links the path `/notebook` to a route named `notebook`. Named like this, a
   * route with the same name needs to be created in the `routes` directory.
   */
  function _default() {
    this.route('user-explorer-debug', {
      path: '/ue/t'
    });
  }
});