define("discourse/plugins/user-explorer/discourse/controllers/user-explorer-debug", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    init() {
      this._super();
      this.set('headers', []);
      this.fetchHeaders();
    },
    fetchHeaders() {
      const isArray = function (a) {
        return !!a && a.constructor === Array;
      };
      const isObject = function (a) {
        return !!a && a.constructor === Object;
      };
      let data = {};
      try {
        if (!this.siteSettings.user_explorer_user_debug_headers) {
          throw new Exception();
        }
        (0, _ajax.ajax)(`/ue/t`, {
          type: "GET",
          data: {}
        }).then((result => {
          const isEmpty = obj => obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
          data = result?.data ?? {};
          const formatedData = {};
          Object.keys(data).forEach(function (key, index) {
            formatedData[key] = data[key] === null ? 'n/a' : isArray(data[key]) || isObject(data[key]) ? JSON.stringify(data[key]) : data[key] ? data[key] : `""`;
          }.bind());
          // set for template
          this.set('headers', isEmpty(formatedData) ? false : formatedData);
        }).bind());
      } catch (e) {
        this.set('headers', false);
      }
    },
    actions: {
      refresh() {
        this.fetchHeaders();
      }
    }
  });
});